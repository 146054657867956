import { ElMessage } from 'element-plus';

// export function isInteger(v: number) {
//   return Object.prototype.toString.call(v) === '[object Number]' && parseInt(v) === v;
// }

// 判断是否支持Local\sessionStroage
export function supportStroage() {
  let flag = true;
  try {
    if (!window.localStorage && !window.sessionStorage) {
      flag = false;
    }
  } catch (e) {
    // 对于无痕模式下会出现异常
    flag = false;
  }
  return flag;
}

export function error() {
  ElMessage({
    type: 'error',
    message: '当前浏览器不支持localStorage或sessionStorage',
  });
}

let win = {};
if (typeof window !== 'undefined') {
  win = window;
}

export const { localStorage, sessionStorage } = win;

const store = {
  local: localStorage,
  session: sessionStorage,
};

// 存在一起 vs 分开存储

// 缓存数据
// const storageData: { local: Record<string, any>; session: Record<string, any> } = {
//   local: {},
//   session: {},
// };

const whiteKeys = ['sso_loginInfo'];
// !添加统一前缀
const getKey = (key) => {
  if (whiteKeys.includes(key)) {
    return key;
  }
  return `pv_platform_${key}`;
};

export class Storage {
  // public type;
  // type local || session
  constructor(type = 'local') {
    this.type = type; // 类型
    // storageData[type] = {};
  }

  set(key, value, isJson) {
    if (!key) return;
    if (!supportStroage()) {
      error();
      return;
    }
    try {
      const _key = getKey(key);

      // storageData[this.type][_key] = value;
      let _value;
      if (isJson) {
        _value = JSON.stringify(value);
      } else {
        _value = value;
      }
      store[this.type].setItem(`${_key}`, _value);
    } catch (e) {
      // localStorage写满时,全清掉
      if (e.name === 'QuotaExceededError') {
        // 暂时全部删除
        // this.clear();
        ElMessage({
          message: e.name,
          type: 'warning',
        });
      }
    }
  }

  get(key, isJosn) {
    if (!key) return;
    if (!supportStroage()) {
      error();
      return;
    }

    const _key = getKey(key);
    // const _value = storageData[this.type][_key];
    // if (_value) {
    //   return _value;
    // }

    let temp = store[this.type].getItem(`${_key}`) || null;

    if (!temp || temp === 'undefined') return '';
    if (isJosn) {
      temp = JSON.parse(temp) || {};
    }
    // storageData[this.type][_key] = temp;
    return temp;
  }
  // 删除
  remove(key) {
    if (!key) return;
    const _key = getKey(key);
    store[this.type].removeItem(_key);
  }
  clear() {
    store[this.type].clear();
  }
}

export const storage = new Storage();
export const session = new Storage('session');
