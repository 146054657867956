<template>
  <div class="index-main-wrapper">
    <Banner />
    <div
      :class="[
        'index-module',
        'module-A',
        moduleStatus['module-A'] ? 'active' : '',
      ]"
      data-module="module-A"
      ref="moduleARef"
    >
      <div class="public-wrapper index-module-content">
        <div class="module-text-content">
          <div class="module-title fade-in">
            【征集启事】<br />药物警戒考核试题大挑战，等你来战！
          </div>
          <div class="module-desc fade-in">
            药物警戒试题征集活动火热进行中，快来贡献你的智慧！
          </div>
          <a
            class="module-more fade-in"
            data-path="/question-collection"
            @click="onMore"
            >查看更多<el-icon><ArrowRightBold /></el-icon
          ></a>
        </div>
        <div class="module-img fade-in">
          <img src="./images/module2.jpg" alt="" />
        </div>
      </div>
    </div>
    <div
      :class="[
        'index-module',
        'module-B',
        moduleStatus['module-B'] ? 'active' : '',
      ]"
      data-module="module-B"
      ref="moduleBRef"
    >
      <div class="public-wrapper index-module-content">
        <div class="module-text-content">
          <div class="module-title fade-in">
            守护生命，责任在肩 <br />药物警戒自评认证在行动
          </div>
          <div class="module-desc fade-in">
            从源头把控，以专业护航 ——
            我们的药物警戒自评认证，为您的安全用药添一份坚实保障！
          </div>
          <a class="module-more fade-in" data-path="/exam-list" @click="onMore"
            >查看更多<el-icon><ArrowRightBold /></el-icon
          ></a>
        </div>
        <div class="module-img fade-in">
          <img src="./images/module3.jpg" alt="" />
        </div>
      </div>
    </div>
    <div
      :class="[
        'index-module',
        'module-C',
        moduleStatus['module-C'] ? 'active' : '',
      ]"
      data-module="module-C"
      ref="moduleCRef"
    >
      <div class="public-wrapper index-module-content">
        <div class="module-text-content">
          <div class="module-title fade-in">
            筑牢安全防线<br />药物警戒大咖带您领略专业深度
          </div>
          <div class="module-desc fade-in">
            专业引领，安全同行 —— 药物警戒大咖云集，守护生命每一刻
          </div>
          <a
            class="module-more fade-in"
            data-path="/expert-list"
            @click="onMore"
            >查看更多<el-icon><ArrowRightBold /></el-icon
          ></a>
        </div>
        <div class="module-img fade-in">
          <img src="./images/module1.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import Banner from './Banner.vue';
import { ArrowRightBold } from '@element-plus/icons-vue';

const router = useRouter();

const moduleARef = ref();
const moduleBRef = ref();
const moduleCRef = ref();
const moduleStatus = reactive({
  'module-A': false,
  'module-B': false,
  'module-C': false,
});
const observerEl = () => {
  var observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // console.log('Element is in viewport:', entry.target.dataset);
        if (entry.target.dataset.module) {
          moduleStatus[entry.target.dataset.module] = true;
        }
      } else {
        // console.log('Element is not in viewport:', entry.target);
      }
    });
  });

  observer.observe(moduleARef.value);
  observer.observe(moduleBRef.value);
  observer.observe(moduleCRef.value);
};
onMounted(() => {
  observerEl();
});

const onMore = (e) => {
  const { path } = e.target.dataset;

  router.push(path);
};
</script>

<style lang="less" scoped>
.index-main-wrapper {
  padding-bottom: 20px;
}

.index-module {
  padding: 20px 0;
  margin-bottom: 26px;
  &.module-A {
    background-color: #eff5ff;
    margin-top: 26px;
  }
  &.module-B {
    background-color: #eff5ff;
  }
  &.module-C {
    background-color: #eff5ff;
  }
  &.active {
    .module-title.fade-in {
      -webkit-animation: fadeInLeft 0.5s 0.5s ease forwards;
      -moz-animation: fadeInLeft 0.5s 0.5s ease forwards;
      -o-animation: fadeInLeft 0.5s 0.5s ease forwards;
      animation: fadeInLeft 0.5s 0.5s ease forwards;
    }

    .module-desc.fade-in {
      -webkit-animation: fadeInLeft 1s 0.5s ease forwards;
      -moz-animation: fadeInLeft 1s 0.5s ease forwards;
      -o-animation: fadeInLeft 1s 0.5s ease forwards;
      animation: fadeInLeft 1s 0.5s ease forwards;
    }

    .module-more.fade-in {
      -webkit-animation: fadeInLeft 1.5s 0.5s ease forwards;
      -moz-animation: fadeInLeft 1.5s 0.5s ease forwards;
      -o-animation: fadeInLeft 1.5s 0.5s ease forwards;
      animation: fadeInLeft 1.5s 0.5s ease forwards;
    }

    .module-img.fade-in {
      -webkit-animation: fadeInRight 0.5s 0.5s ease forwards;
      -moz-animation: fadeInRight 0.5s 0.5s ease forwards;
      -o-animation: fadeInRight 0.5s 0.5s ease forwards;
      animation: fadeInRight 0.5s 0.5s ease forwards;
    }
  }
}

.index-module-content {
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  border: 1px solid #ebebeb;
  box-shadow: 1px 1px 10px rgba(62, 75, 96, 0.07);
  padding: 32px 32px 32px 40px;
  box-sizing: border-box;
  .module-text-content {
    flex: 1;
    margin-right: 20px;
    padding-top: 30px;
    .module-title {
      color: #1d1d1f;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      opacity: 0;
    }
    .module-desc {
      margin-top: 17px;
      color: #757d8e;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      opacity: 0;
    }
    .module-more {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      color: #0068ff;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      margin-top: 30px;
      opacity: 0;
    }
  }
  .module-img {
    width: 600px;
    height: 350px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    opacity: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
</style>
