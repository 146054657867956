<template>
  <div class="index-banner-wrapper">
    <div class="public-wrapper">
      <el-carousel height="420px">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img
            :style="{ width: '100%', height: '100%' }"
            :src="`${'/api/sci-vigi'}/support/file/preview/${item.fileId}`"
            alt=""
            class="banner-item-img"
            @click="goLink(item.linkUrl)"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script setup>
import { http } from '@/http/client';
import { onMounted, ref } from 'vue';

const bannerList = ref([]);
const getBannerList = async () => {
  try {
    const res = await http.get('/platform/web/carousel/getCarouselList');
    bannerList.value = res || [];
  } catch (error) {
    //
  }
};
const goLink = (link) => {
  if (!link) return;
  window.open(link, '_blank');
};
onMounted(() => {
  getBannerList();
});
</script>

<style lang="less" scoped>
.index-banner-wrapper {
  background-color: #fff;
  .banner-item-img {
    cursor: pointer;
  }
}
</style>
