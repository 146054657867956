import Cookies from 'js-cookie';

// const parseCookie = (cookieStr) => {
//   const res = {};
//   if (!cookieStr) {
//     return res;
//   }
//   const cookiesArr = cookieStr.split(";").filter((i) => !!i);

//   cookiesArr.forEach((item) => {
//     const kv = item.split("=");
//     if (kv && kv.length > 0) {
//       res[kv[0].trim()] = decodeURIComponent(kv[1]);
//     }
//   });

//   return res;
// };

const setCookie = (name, value, days, domain) => {
  // if (!isBrowser()) {
  //   console.error('can not set cookie in server');
  //   return;
  // }
  const expires = days || 30;

  if (domain) {
    Cookies.set(name, value, { expires, domain });
  } else {
    Cookies.set(name, value, { expires });
  }
};

const getCookie = (name) => {
  let cookie = '';
  // if (!isBrowser()) {
  //   cookie = parseCookie(global?.ssr?.context?.req?.cookie)[name];
  // } else {
  cookie = Cookies.get(name);
  // }
  return cookie;
};

const removeCookie = (name) => {
  if (!name) return;
  Cookies.remove(name);
};

export { setCookie, getCookie, removeCookie };
